.navbar {
  padding: 20px 0 !important;
}

.navbar.sticky {
  -webkit-animation: fixed 1.2s;
  animation: fixed 1.2s;
  box-shadow: 1px 1px 12px 0px #e3e3e3;
}

.navbar .navbar-brand {
  max-width: 400px;
  padding: 0;
}

.navbar .navbar-brand img {
  width: 60%;
}

.navbar .navbar-collapse {
  justify-content: flex-end;
}

.navbar .navbar-nav {
  align-items: center;
}

.navbar .nav-item a:not(.btn-link) {
  color: #222f3d;
  font-size: 21px;
  padding: 10px 2px;
  font-weight: 700;
  position: relative;
  cursor: pointer;
}

.navbar .nav-item:not(:last-child) {
  margin-right: 30px;
}

.navbar .nav-item .btn {
  font-weight: 500;
}

.navbar.navbar-expand-md .navbar-nav a:not(.btn-link):after,
.navbar.navbar-expand-md .navbar-nav a:after {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #222f3d;
  transition: 0.3s ease-in-out;
  content: "";
  left: 0;
  width: 100%;
  transform: scaleX(0);
}

.navbar.navbar-expand-md .navbar-nav a:not(.btn-link):hover:after,
.navbar.navbar-expand-md .navbar-nav .active a:after {
  transform: scale(1);
}

.toogle-button {
  width: 25px;
  height: 4px;
  background-color: #002249;
  margin-top: 3px;
  display: block;
  outline: none;
}

@media (max-width: 1440px) {
  .navbar .navbar-brand {
    max-width: 320px;
  }

  .navbar .nav-item a:not(.btn-link) {
    font-size: 18px;
  }

  .navbar .nav-item:not(:last-child) {
    margin-right: 20px;
  }
}

@media (max-width: 1199px) {
  .navbar .nav-item:not(:last-child) {
    margin-right: 12px;
  }

  .navbar .nav-item a:not(.btn-link) {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .navbar .navbar-brand {
    max-width: 175px;
  }

  .navbar .nav-item a:not(.btn-link) {
    font-size: 14px;
  }

  .navbar .navbar-brand img {
    width: 85%;
  }
}

@media (max-width: 767px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar-collapse {
    position: absolute;
    top: 80px;
    background: #fff;
    width: 100%;
    padding: 0 30px;
    box-shadow: 9px 40px 34px 0px rgba(0, 0, 0, 0.3);
    left: 0;
    padding: 0;
  }

  .navbar .nav-item:not(:last-child) {
    margin-right: 0;
  }

  .navbar .navbar-nav {
    padding: 20px;
  }

  .navbar .nav-item {
    display: block;
    width: 100%;
    text-align: center;
  }

  .navbar .nav-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .navbar .nav-item a:not(.btn-link) {
    font-size: 16px;
  }

  .navbar .btn {
    font-size: 16px;
    width: 100%;
    padding: 10px 25px;
  }

  .navbar-toggler:focus {
    outline: none;
    color: transparent;
  }
}
