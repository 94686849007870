.customers-section {
  padding: 85px 0;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

@media (max-width: 1099px) {
  .customers-section {
    padding: 60px 0;
  }
}
