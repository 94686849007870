.solution-inner-body p {
  margin-bottom: 0px;
}

.solution-image {
  width: 100%;
  max-width: 670px;
}

@media (max-width: 1199px) {
  .solution-image {
    width: 105%;
  }
}

@media (max-width: 991px) {
  .solution-image-container {
    text-align: center;
  }

  .solution-image {
    width: 90%;
    margin-top: 24px;
  }
}
