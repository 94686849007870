@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Noto+Sans:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --primary: rgb(239, 80, 81);
  --secondary: #222f3d;
  --disable: #f8646b;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  line-height: 30px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(236, 34, 37)),
    color-stop(0.72, rgb(194, 14, 25)),
    color-stop(0.86, rgb(148, 8, 13))
  );
}

h6,
.h6 {
  font-size: 2rem;
  font-weight: bold;
}

a:hover {
  text-decoration: none;
}

.container {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
}

.section_fit {
  min-height: calc(100vh - 112px);
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-inner {
  width: 90%;
  margin: 0 auto;
  padding: 0 15px;
}

.section-heading {
  font-size: 2.2vw;
  color: var(--secondary);
  line-height: 1.2em;
  font-weight: 700;
  margin-bottom: 20px;
}

.section-title {
  margin-bottom: 30px;
}

.section-title.text-center {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.section-title p {
  margin-bottom: 20px;
}

.section-title > span {
  font-size: 28px;
  font-weight: 500;
  color: #ef5051;
  display: block;
  margin-bottom: 8px;
}

.section-subheading {
  font-size: x-large;
  font-weight: bold;
}

.text-brand {
  color: var(--primary);
}

.text-brand-dark {
  color: var(--secondary);
}

.dark-section {
  background: #f4f4f4;
}

.btn {
  font-size: 18px;
  padding: 10px 25px;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  font-weight: bold;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--secondary);
  border-color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--disable);
  border-color: var(--disable);
}

/* Media Queries */
@media (max-width: 1440px) {
  .btn {
    font-size: 20px;
    padding: 7px 25px;
    text-wrap: nowrap;
  }

  .section_fit {
    min-height: calc(100vh - 98px);
  }
}

@media (max-width: 1199px) {
  .container {
    width: 90%;
  }

  .container-inner {
    width: 95%;
  }

  .btn {
    font-size: 16px;
    padding: 5px 20px;
  }

  .section_fit {
    padding: 30px 0;
  }
}

@media (max-width: 991px) {
  .section-title > span {
    font-size: 18px;
  }

  .section-heading {
    font-size: 26px;
    margin-bottom: 10px;
  }

  .btn {
    font-size: 14px;
    padding: 5px 15px;
  }

  .section_fit {
    min-height: calc(100vh - 85px);
    padding: 35px 0;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 16px;
  }

  .container {
    width: 100%;
  }

  .container-inner {
    width: 100%;
    justify-content: space-between;
  }

  .section-title > span {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .btn {
    font-size: 12px;
    padding: 5px 25px;
  }

  .section-heading {
    font-size: 30px;
  }
}

@media (max-width: 479px) {
  .section-heading {
    font-size: 25px;
  }
}

/*=======  Animations  =======*/
@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@keyframes sticky {
  0% {
    top: -200px;
  }

  100% {
    top: 0;
  }
}

@keyframes scale-up-one {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

@keyframes boxImageAnimationSignal {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  1% {
    opacity: 0.14;
  }

  20% {
    opacity: 0.3;
  }

  40% {
    opacity: 0.4;
  }

  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes stickyRight {
  0% {
    right: -50px;
  }

  100% {
    right: 0;
  }
}

@keyframes stickyRight {
  0% {
    right: -50px;
  }

  100% {
    right: 0;
  }
}
