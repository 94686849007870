/* .inner-content {
  width: 100%;
  padding: 0 2.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 959px) {
  .inner-content {
    padding: 0 2rem;
  }
}

@media (max-width: 419px) {
  .inner-content {
    padding: 0 1.5rem;
  }
} */

.slider {
  padding: 0 2rem;
  margin-top: 1rem;
}

.slider .label {
  font-size: 0.75rem;
}

.slider .requests {
  margin-top: 2.5rem;
  font-size: 20px;
  font-weight: 700;
}

.price-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  margin: 0.5rem;
  width: 100%;
}

.price-card .price-content {
  width: 100%;
  flex: 1;
  border-radius: 15px;
  padding-bottom: 16px;
  background-color: #f5f5f5;
  box-shadow: 9px 0px 34px 0px rgba(0, 0, 0, 0.3), 9px 29px 34px 0px rgba(0, 0, 0, 0.3);
}

.price-card .price-cover {
  width: 100%;
  border-radius: 15px 15px 5rem 5rem;
}

.price-card .price-title {
  flex: 0 1 auto;
  margin: 0 0 0.2rem;
  padding: 1.5rem 0 0;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price-card .price-description {
  flex: 0 1 auto;
  width: 100%;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 300;
}

.price-card .price-amount {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  padding: 1.5rem 1rem;
  font-weight: 700;
}

.price-card .price-amount .amount {
  font-size: 2.5rem;
}

.price-card .price-amount .unit {
  font-size: 1.3rem;
  margin-left: 0.3rem;
}

.price-card .price-amount .currency {
  font-size: 1.1rem;
  align-self: flex-start;
  margin: 0.6rem 0.15rem 0 0;
}

.price-card .price-feature {
  margin: 1rem;
  padding: 1rem 1.5rem;
  list-style-type: none;
  font-size: 0.85rem;
  line-height: 1.6rem;
}

.price-card .price-action {
  width: 100%;
  padding: 1rem;
  text-align: center;
}

.price-card .price-action .price-select {
  margin: 0;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.price-card.personal .price-content {
  border-color: rgba(239, 80, 81, 0.4);
}

.price-card.personal .price-cover {
  color: var(--primary);
}

.price-card.personal .price-select {
  border-color: rgba(239, 80, 81, 0.4);
  color: var(--primary);
}

.price-card.professional .price-content {
  border-color: rgba(239, 80, 81, 0.5);
}

.price-card.professional .price-cover {
  color: #fff;
  background-color: var(--primary);
}

.price-card.professional .price-select {
  border-color: rgba(239, 80, 81, 0.5);
  background-color: var(--primary);
  color: #fff;
}

.price-card.business .price-content {
  border-color: rgba(239, 80, 81, 0.15);
}

.price-card.business .price-cover,
.price-card.business .price-select {
  color: var(--primary);
  background-color: rgba(239, 80, 81, 0.15);
}

.price-card.business .price-select {
  border-color: rgba(239, 80, 81, 0.15);
}

.price-card.business .price-select:focus,
.price-card.business .price-select:hover {
  background-color: rgba(239, 80, 81, 0.25);
}

.ejs-switch-button {
  display: inline-block;
  white-space: nowrap;
  line-height: 2.25rem;
  margin: 6px 8px;
}

.ejs-switch-button label {
  vertical-align: middle;
  cursor: pointer;
  margin: 0 4px;
}

.ejs-switch {
  display: inline-block;
  position: relative;
  min-height: 2rem;
  min-width: 4rem;
  align-items: center;
  text-align: center;
  border-radius: 2rem;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  outline: none;
  border: 0;
  background: var(--secondary);
  color: currentColor;
  text-decoration: none;
  overflow: hidden;
}

.ejs-switch .ejs-switch-input {
  display: none;
}

.ejs-switch .ejs-switch-pan {
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(50% - 2px);
  height: calc(100% - 4px);
  border-radius: 10rem;
  background-color: #fff;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ejs-switch .ejs-switch-input:checked ~ .ejs-switch-pan {
  transform: translate(100%);
}

/* .pricing-faq {
  padding: 3rem 0;
  text-align: center;
}

.pricing-faq .page-parts-title {
  margin: 1rem 0;
  font-size: 2.4rem;
  font-weight: 700;
}

.pricing-faq .page-parts-label {
  color: #fca253;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.pricing-faq .faq {
  margin-top: 2rem;
  text-align: left;
}

.pricing-faq .action-button {
  margin: 2rem 0 0;
  padding: 0.5rem 2rem;
}

@media (max-width: 719px) {
  .pricing-faq .page-parts-title {
    font-size: 2rem;
  }
} */


.plans-comparison {
  padding: 3rem 0;
  text-align: center;
}

.plans-comparison .page-parts-title {
  margin: 1rem 0;
  font-size: 2.4rem;
  font-weight: 700;
}

.plans-comparison .page-parts-label {
  color: #fca253;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.plans-comparison .plans-comparison-table {
  margin-top: 2rem;
  overflow-x: auto;
}

.plans-comparison .plans-comparison-table .plans-comparison-row,
.plans-comparison .plans-comparison-table .plans-comparison-title {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 35rem;
  border-radius: 0.15rem 0.3rem 0.3rem 0.15rem;
  margin: 1rem 0;
}

.plans-comparison .plans-comparison-table .plans-comparison-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  flex: 1 0 5rem;
  height: 4rem;
  padding: 0.7rem 1.5rem;
}

.plans-comparison .plans-comparison-table .plans-comparison-cell:first-of-type {
  flex-direction: row;
  border: none;
  justify-content: flex-start;
  text-align: left;
  flex: 1 0 11rem;
}

.plans-comparison .plans-comparison-table .plans-comparison-cell .price-amount {
  margin-top: 0.3rem;
  font-size: 0.85rem;
  font-weight: 400;
}

.plans-comparison .plans-comparison-table .plans-comparison-help {
  margin-left: 0.5rem;
}

.plans-comparison .plans-comparison-table .plans-comparison-row {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 2px solid var(--primary);
}

.plans-comparison .plans-comparison-table .plans-comparison-row:hover {
  background: rgba(44, 62, 80, 0.05);
}

.plans-comparison .plans-comparison-table .plans-comparison-title {
  font-weight: 600;
}

.plans-comparison
.plans-comparison-table
.plans-comparison-title
.plans-comparison-cell {
  border: none;
}

.plans-comparison .footnotes {
  text-align: left;
  font-size: 0.9rem;
}

.plans-comparison .action-button {
  margin: 2rem 0 0;
  padding: 0.5rem 2rem;
}

.plan-feature-container {
  display: flex;
  align-items: flex-start;
}

.plan-feature-container span {
  font-size: 20px;
  /* color: #ef5051; */
  color: #34b233;
  margin-right: 8px;
}

.plan-feature-container .not-included {
  color: #ef5051;
}

.plan-feature {
  font-size: 18px;
  font-weight: 400;
  line-height: inherit;
}

@media (max-width: 719px) {
  .plans-comparison .page-parts-title {
    font-size: 2rem;
  }

  .plans-comparison
    .plans-comparison-table
    .plans-comparison-cell:first-of-type {
    flex-basis: 5rem;
  }
}

.pricing-page {
  background: transparent top no-repeat;
  padding-bottom: 4rem;
}

.pricing-page .pricing-page-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 12.2rem;
  background-color: #fca253;
  z-index: 1;
}

.pricing-page .pricing-inner-content {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 4rem;
  text-align: center;
  z-index: 2;
}

.pricing-page .page-title {
  margin: 0 0 8rem;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
}

.pricing-page .pricing-plan-wrapper .pricing-plan-switch {
  margin-left: 0;
  margin-right: 0;
}

.pricing-page .pricing-plan-wrapper .pricing-plan-select {
  display: inline-block;
  margin: 2px 0.5rem;
  color: #fca253;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
  width: 11.2rem;
}

.pricing-page .pricing-plan-wrapper .pricing-plan-select.pricing-plan-right {
  text-align: right;
}

.pricing-page .pricing-plan-wrapper .pricing-sale {
  color: var(--primary);
}

.pricing-page .prices {
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;
}

.pricing-page .prices .price-page-card {
  min-width: 0;
  max-width: 40rem;
}

.pricing-page .prices .price-page-card:first-of-type {
  margin-left: 0;
}

.pricing-page .prices .price-page-card:last-of-type {
  margin-right: 0;
}

.pricing-page .highlights-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 1.7rem;
  background-color: rgba(252, 162, 83, 0.2);
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
}

.pricing-page .highlights-bar .highlights-bar-text {
  min-width: 0;
  flex: 1;
}

.pricing-page .highlights-bar .action-button {
  margin: 0;
  padding: 0 1.4rem;
}

.pricing-page .faq {
  margin-top: 2rem;
  text-align: left;
}

.pricing-page .faq a:hover {
  text-decoration: underline;
}

.pricing-page .attachment-size-issue:after {
  content: "1";
  vertical-align: super;
  line-height: 1;
  font-size: 0.65rem;
  color: #fca253;
}

@media (max-width: 959px) {
  .pricing-page .prices {
    flex-wrap: wrap;
    justify-content: center;
  }

  .pricing-page .prices .price-page-card {
    flex: 1 0 18rem;
  }

  .pricing-page .prices .price-page-card:nth-child(2) {
    margin-right: 0;
  }

  .pricing-page .prices .price-page-card:nth-child(3) {
    margin-left: 0;
  }
}

@media (max-width: 719px) {
  .pricing-page .page-title {
    margin-top: 0.5rem;
    font-size: 1.5rem;
  }

  .pricing-page .pricing-plan-wrapper .pricing-plan-select {
    display: inline-flex;
    flex-direction: column;
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    width: 5rem;
  }

  .pricing-page .pricing-plan-wrapper .pricing-sale {
    font-size: 0.9rem;
  }

  .pricing-page .prices {
    flex-wrap: wrap;
    justify-content: center;
  }

  .pricing-page .prices .price-page-card {
    flex-basis: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .pricing-page .highlights-bar {
    padding: 0.6rem 1rem;
    font-size: 1rem;
  }

  .pricing-page .highlights-bar .action-button {
    padding: 0.3rem 1rem;
  }
}

.ejs-input-container {
  display: inline-block;
  position: relative;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 6px 8px;
  width: 100%;
  overflow: hidden;
}

.ejs-input-container .ejs-input-icon {
  position: absolute;
  top: 50%;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding-left: 2px;
  z-index: 10;
  border-radius: 2rem;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translate(-0.3rem, -50%);
}

.ejs-input-container .ejs-input {
  width: 100%;
  min-height: 2.25rem;
  min-width: 88px;
  vertical-align: middle;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
  margin: 0;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  font-weight: 400;
  font-size: 1rem;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  resize: none;
  transition: border 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ejs-input-container .ejs-input.has-icon {
  padding-right: 3rem;
}

.ejs-input-container .ejs-input.has-icon ~ .ejs-input-icon {
  display: flex;
}

.ejs-input-container .ejs-input:disabled,
.ejs-input-container .ejs-input[disabled] {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
  background-color: rgba(44, 62, 80, 0.05);
}

.ejs-input-container .ejs-input:not([disabled]):focus {
  border-color: #fca253;
}

.ejs-input-container .ejs-input:not([disabled]).is-touched:invalid {
  border-color: #ef5771;
}

.ejs-input-container
  .ejs-input:not([disabled]).is-touched:invalid::-moz-placeholder {
  color: #ef5771;
}

.ejs-input-container
  .ejs-input:not([disabled]).is-touched:invalid::placeholder {
  color: #ef5771;
}

.ejs-text-area-container {
  display: inline-block;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 0.75rem 0.5rem 0.75rem 1.5rem;
  margin: 6px 8px;
  width: 100%;
  overflow: hidden;
  transition: border 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ejs-text-area-container .ejs-text-area {
  width: 100%;
  height: 100%;
  min-height: 2.25rem;
  min-width: 88px;
  vertical-align: middle;
  align-items: center;
  text-align: left;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  background: transparent;
  color: currentColor;
  white-space: normal;
  font-weight: 400;
  font-size: 1rem;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  resize: none;
  overflow-x: hidden;
}

.ejs-text-area-container .ejs-text-area:disabled,
.ejs-text-area-container .ejs-text-area[disabled] {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
  background-color: rgba(44, 62, 80, 0.05);
}

.ejs-text-area-container
  .ejs-text-area:not([disabled]).is-touched:invalid::-moz-placeholder {
  color: #ef5771;
}

.ejs-text-area-container
  .ejs-text-area:not([disabled]).is-touched:invalid::placeholder {
  color: #ef5771;
}

.ejs-text-area-container.is-focused {
  border-color: #fca253;
}

.ejs-text-area-container.is-touched.is-invalid {
  border-color: #ef5771;
}

.personal-plan-comparison .personal-plan-comparison-table th {
  text-align: center;
  color: #2c3e50;
  font-weight: 700;
}

.personal-plan-comparison .personal-plan-comparison-table td {
  line-height: 1.15;
  border-bottom: none;
}

.personal-plan-comparison .personal-plan-comparison-table h4:first-child {
  margin-bottom: 0;
}

.personal-plan-comparison .personal-plan-comparison-table .price-card {
  width: 17rem;
  margin: 0;
}

.personal-plan-comparison
  .personal-plan-comparison-table
  .price-card
  .price-action {
  display: none;
}

.personal-plan-comparison
  .personal-plan-comparison-table
  .personal-plan-switch {
  margin: 0;
  padding-right: 1.5rem;
  width: 100%;
  text-align: center;
}

.personal-plan-comparison
  .personal-plan-comparison-table
  .personal-plan-switch
  .ejs-switch {
  min-height: 1.5rem;
  min-width: 3rem;
  background: var(--primary);
}

.personal-plan-comparison
.personal-plan-comparison-table
.new-plan-comparison
.requests,
.personal-plan-comparison
.personal-plan-comparison-table
.new-plan-comparison
li:first-child {
  font-weight: 700;
}

.professional-plan-comparison .professional-plan-comparison-table th {
  text-align: center;
  color: #2c3e50;
  font-weight: 700;
}

.professional-plan-comparison .professional-plan-comparison-table td {
  line-height: 1.15;
  border-bottom: none;
  min-width: 12rem;
}

.professional-plan-comparison
  .professional-plan-comparison-table
  h4:first-child {
  margin-bottom: 0;
}

.professional-plan-comparison .professional-plan-comparison-table .price-card {
  width: 17rem;
  margin: 0;
}

.professional-plan-comparison
  .professional-plan-comparison-table
  .price-card
  .price-action {
  display: none;
}

.professional-plan-comparison
  .professional-plan-comparison-table
  .professional-plan-switch {
  margin: 0;
  padding-right: 1.25rem;
  width: 100%;
  text-align: center;
}

.professional-plan-comparison
  .professional-plan-comparison-table
  .professional-plan-switch
  .ejs-switch {
  min-height: 1.5rem;
  min-width: 3rem;
  background: var(--primary);
}
