.demo-call {
  background-color: #222f3d;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 3rem 6rem;
  align-items: center;
  justify-content: center;
}
.demo-tagline {
  font-weight: bold;
  font-size: xx-large;
  color: #fff;
}

.demo-button {
  margin-top: 1.5rem;
  justify-content: center;
}

@media (max-width: 991px) {
  .demo-call {
    padding: 3rem;
  }
  .demo-tagline {
    font-weight: bold;
    font-size: x-large;
  }
}

@media (max-width: 480px) {
  .demo-call {
    font-size: small; 
  }
  .demo-tagline {
    font-weight: bold;
    font-size: large;
  }
}
