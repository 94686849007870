#faqAccordion.accordion {
  --bs-accordion-border: 0;
  --bs-accordion-active-bg: #fff;
  --bs-accordion-border-color: #fff;
  --bs-accordion-btn-focus-border-color: var(--secondary);
  --bs-accordion-inner-border-radius: 50px;
  --bs-accordion-btn-focus-box-shadow: none;
}

#faqAccordion.accordion .accordion-item:first-of-type .accordion-button {
  border-radius: 50px;
}

#faqAccordion.accordion .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 50px;
}

#faqAccordion.accordion .accordion-item .accordion-button {
  border: 1px solid var(--secondary);
  border-radius: 50px;
  font-size: large;
  font-weight: 550;
  font-family: "Noto Sans", sans-serif;
}

#faqAccordion.accordion .accordion-header {
  margin-bottom: 16px;
}

#faqAccordion.accordion .accordion-collapse{
  padding: 0 1.5rem;
  margin: 0.5rem 0;
}

#faqAccordion.accordion .accordion-item{
  border: 0;
}
