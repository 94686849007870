.contact-section {
    padding: 50px 0px !important;
  }
  
  .helper-text {
    margin-bottom: 30px;
  }
  
  .contact-form form {
    margin: 0 -8px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .contact-form form .form-group {
    flex: 0 0 auto;
    width: 50%;
    padding: 0 8px;
    margin-bottom: 1rem;
  }
  
  .contact-form form .form-group.full-width {
    width: 100%;
    flex: 0 0 auto;
  }
  
  .contact-section .form-control {
    display: inline-block;
    font-weight: 300;
    padding: 1em 2em;
    border-radius: 1em;
    margin-bottom: 0;
    border-color: #eee;
    margin-right: 0;
    resize: none;
  }
  
  .contact-section .form-control:nth-child(2n + 0) {
    margin-right: 0;
  }
  
  .form-control:focus {
    outline: none;
    border-color: #0071c3;
    box-shadow: none;
  }
  
  .contact-section .form-control.full-width {
    width: 100%;
    height: 120px;
  }
  
  .contact-section .btn-primary {
    clear: both;
    margin: 0 auto 0;
    display: block;
  }
  
  .img-fluid {
    width: 90%;
  }
  
  .character-count {
    text-align: right;
    font-size: 16px;
  }
  
  @media (max-width: 991px) {
    .contact-form {
      margin-bottom: 20px;
    }
  
    .contact-section .contact-vectore {
      text-align: center;
    }
  }
  
  @media (max-width: 991px) {
    .contact-form form .form-group {
      width: 100%;
    }
  
    .contact-section .form-control {
      padding: 0.7em 1.2em;
      border-radius: 1.5em;
    }
  }
  