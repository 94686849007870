/* solution-points-accodion */
#solutionPoints.accordion {
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: none;
  --bs-accordion-btn-icon-width: 0;
  --bs-accordion-btn-active-icon: none;
  --bs-accordion-btn-focus-border-color: #fff;
  --bs-accordion-btn-focus-box-shadow: 0;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #000;
  --bs-accordion-active-bg: #fff;
}

#solutionPoints.accordion .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#solutionPoints.accordion .accordion-item {
  border: 0;
}

#solutionPoints.accordion .accordion-body {
  border: 0;
  border-bottom: 1px solid lightgray;
  padding-left: 5rem;
}

#solutionPoints.accordion .accordion-button {
  font-family: "Noto Sans", sans-serif;
}

#solutionPoints.accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
  border: 0;
  border-top: 3px solid black;
}

#solutionPoints.accordion .accordion-button:focus {
  box-shadow: none;
}

@media (max-width: 991px) {
  #solutionPoints.accordion {
    --bs-accordion-body-padding-y: 0.5rem;
  }
}

/* SolutionPoint */

.solution-point-title {
  font-size: x-large;
  font-weight: bold;
}

@media (max-width: 991px) {
  .solution-point-title {
    font-size: medium;
  }
}

/* Active Content */

.active-contents .active-content .counter {
  color: #fff;
  font-size: 25px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-align: start;
  font-weight: normal;
  text-wrap: balance;
  line-height: 1.2;
}

.active-contents .active-content.active-content-three {
  display: flex;
  align-items: center;
  background-color: #ef5051;
  color: #fff;
  border-radius: 15px;
  padding: 30px 60px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
  height: 480px;
}

.active-contents .active-content.active-content-three::before {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../public/assets/images/section-images/fact-box-before.png);
  background-size: cover;
  content: "";
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 1199px) {
  .active-contents .active-content.active-content-three .counter {
    font-size: 20px;
    line-height: 1.2;
  }
}

@media (max-width: 991px) {
  .active-contents .active-content.active-content-three {
    padding: 20px 30px;
    height: 400px;
  }

  .active-contents .active-content.active-content-three .counter {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .active-contents .active-content.active-content-three {
    padding: 30px 30px;
    height: 430px;
  }

  .active-contents .active-content.active-content-three .counter {
    font-size: 18px;
    align-self: start;
  }
}

@media (max-width: 375px) {
  .active-contents .active-content.active-content-three {
    padding: 30px 30px;
    height: 400px;
  }

  .active-contents .active-content.active-content-three .counter {
    font-size: 15px;
    align-self: start;
  }
}

/* Swiper */

.mySwiper {
  --swiper-theme-color: var(--secondary);
}
