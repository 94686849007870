.features-banner-section .section-title {
  margin-bottom: 20px;
}

.features-banner-section .features-banner-body .features-banner-sub-heading {
  margin-bottom: 16px;
}

.features-banner-section .features-banner-body .features-banner-body-item span {
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  background: #f5f5f5;
  box-shadow: 0 4px 8px 0 rgba(160, 194, 255, 0.2);
  font-size: 20px;
  color: #ef5051;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: absolute;
  top: 0px;
  left: 0px;
}

.features-banner-section .features-banner-body .features-banner-body-item {
  position: relative;
  padding-left: 55px;
  margin-bottom: 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.features-banner-section .features-banner-body .features-banner-body-item .item-animation {
  visibility: visible;
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-name: fadeInLeft;
}

.features-banner-section .features-banner-body .features-banner-body-item .title {
  font-size: 18px;
  font-weight: 400;
  line-height: inherit;
  margin-bottom: 0px;
}

.features-banner-section .features-banner-body .features-banner-body-item p {
  color: #81a3bb;
  padding-top: 0;
  padding-right: 80px;
}

.features-banner-section .features-banner-body .features-banner-body-item:hover span {
  background: #ef5051;
  color: #fff;
}

.features-banner-body p {
  margin-bottom: 40px;
}

.features-banner-image-container .features-banner-image {
  width: 100%;
  max-width: 670px;
}

.features-banner-sub-body p {
  margin-bottom: 0px;
}

@media (max-width: 1199px) {
  .features-banner-image-container .features-banner-image {
    width: 105%;
  }
}

@media (max-width: 991px) {
  .features-banner-section .section-title {
    margin-bottom: 12px;
  }

  .features-banner-image-container {
    text-align: center;
  }

  .features-banner-image-container .features-banner-image {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .features-banner-section .section-title {
    margin-bottom: 0px;
  }

  .features-banner-section .features-banner-body .features-banner-body-item .title {
    font-size: 15px;
  }

  .features-banner-section .features-banner-body .features-banner-body-item {
    margin-bottom: 10px;
    padding-left: 40px;
  }

  .features-banner-section .features-banner-body .features-banner-body-item span {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-top: 4px;
  }

  .features-banner-image-container .features-banner-image {
    width: 100%;
    max-height: 300px;
  }
}
