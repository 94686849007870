.alert-banner {
  background-color: #222f3d;
}

.alert-banner .alert-banner-text {
  color: #fff;
  font-size: medium;
}
.alert-banner .alert-banner-text:hover {
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .alert-banner {
    padding: 0 15px;
    font-size: small;
  }
  .alert-banner .alert-banner-text {
    font-size: small;
  }
}
@media screen and (max-width: 767px) {
  .alert-banner {
    padding: 0px 20px;
    font-size: small;
    text-align: center;
  }
  .alert-banner .alert-banner-text {
    font-size: small;
  }
}
