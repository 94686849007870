.banner-h2 {
  font-size: 55px;
  color: var(--darkgray);
  font-weight: 700;
  line-height: 1.1;
  font-family: "Noto Sans", sans-serif;
}

.banner-p {
  font-size: 18px;
  color: var(--darkgray);
  line-height: 24px;
  font-weight: 400;
  margin: 32px 0px !important;
}

.banner-span {
  color: #ef5051;
}

.banner-btn {
  background-color: var(--darkgray);
  border-radius: 25px;
  padding: 12px 30px;
  color: var(--white) !important;
  font-size: 18px;
  font-weight: 500;
  width: 185px;
  text-align: center;
}

.banner-btn:hover {
  background-color: var(--yellow);
  color: var(--darkgray) !important;
}

.banner-btn-div {
  display: flex;
  gap: 0.5rem;
}

.banner-image {
  max-width: 709px;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .banner-p {
    font-size: 17px;
    line-height: 24px;
  }
}

@media screen and (max-width: 991px) {
  .banner-h2 {
    font-size: 40px;
  }

  .banner-p {
    font-size: 16px;
    line-height: 22px;
  }

  .banner-btn {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .banner-section .row {
    padding: 1rem 0 !important;
  }

  .banner-h2 {
    font-size: 35px;
    text-align: center;
    margin-bottom: 20px;
  }

  .banner-p {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    margin: 20px 0px !important;
  }

  .banner-btn {
    font-size: 14px;
    width: unset;
    padding: 8px 20px;
  }

  .banner-btn-div {
    justify-content: center;
    margin: 20px 0;
  }

  .banner-image {
    max-width: 600px;
    width: 100%;
    margin-top: 20px;
  }

  .banner-image-container {
    text-align: center;
  }
}
