.key-benefits-section {
  position: relative;
  z-index: 1;
}

.key-benefits .key-benefit .icon {
  color: #ef5051;
  line-height: 1;
  margin-bottom: 20px;
  font-size: 50px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.key-benefits .key-benefit .counter {
  font-size: 30px;
  letter-spacing: -1px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #000
}

.key-benefits .key-benefit p.title {
  line-height: 1.5;
  margin-top: 5px;
  font-size: 15px;
}

.key-benefits .key-benefit.key-benefit-three {
  background: #f5f5f5;
  color: #ef5051;
  border-radius: 15px;
  padding: 50px 30px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
  height: 100%;
}

.key-benefits .key-benefit.key-benefit-three::before {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../public/assets/images/section-images/fact-box-before.png);
  background-size: cover;
  content: "";
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.key-benefits .key-benefit.key-benefit-three:hover {
  background-color: #ef5051;
}

.key-benefits .key-benefit.key-benefit-three:hover,
.key-benefits .key-benefit.key-benefit-three:hover .icon,
.key-benefits .key-benefit.key-benefit-three:hover .counter,
.key-benefits .key-benefit.key-benefit-three:hover .p {
  color: #fff;
}

.key-benefits .key-benefit.key-benefit-three:hover::before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 991px) {
  .key-benefits .key-benefit.key-benefit-three .counter {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .key-benefits {
    margin: 10px;
  }
}
