.features-section {
  position: relative;
  z-index: 1;
}

.features .feature .icon {
  color: #ef5051;
  line-height: 1;
  margin-bottom: 20px;
  font-size: 50px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.features .feature .counter {
  font-size: 18px;
  letter-spacing: -1px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #000;
  font-weight: normal;
}

.features .feature p.title {
  line-height: 1.5;
  margin-top: 5px;
  font-size: 15px;
  text-align: center;
  text-wrap: balance;
}

.features .feature.feature-three {
  background: #f5f5f5;
  color: #ef5051;
  border-radius: 15px;
  padding: 30px 30px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
  height: 100%;
}

.features .feature.feature-three::before {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../public/assets/images/section-images/fact-box-before.png);
  background-size: cover;
  content: "";
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.features .feature.feature-three:hover {
  background-color: #ef5051;
}

.features .feature.feature-three:hover,
.features .feature.feature-three:hover .icon,
.features .feature.feature-three:hover .counter,
.features .feature.feature-three:hover .p {
  color: #fff;
}

.features .feature.feature-three:hover::before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 991px) {
  .features .feature.feature-three .counter {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .features {
    margin: 10px;
  }

  .features .feature.feature-three .counter {
    font-size: 15px;
  }
}
  