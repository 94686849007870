footer {
  background: #222f3d;
  background-image: url(../../../public/assets/images/section-images/fact-box-before.png);
  background-size: 33.3%;
}

footer p {
  color: #fff;
  font-size: 16px;
}

footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-logo {
  margin-bottom: 10px;
  max-width: 50%;
}

.contact-link {
  background-color: #e2e2e2;
  padding: 35px 0;
}

.contact-box ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.page-footer {
  padding: 30px 0;
}

.page-footer h6 {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.page-footer p {
  max-width: 300px;
}

.page-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.page-footer .contact-us ul {
  padding-left: 5px;
}

.page-footer .footer-links ul li,
.page-footer .contact-us ul li {
  margin-bottom: 15px;
}

.page-footer .footer-links a {
  text-transform: capitalize;
  cursor: pointer;
}

.page-footer ul li a,
.page-footer ul li {
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  color: #fff;
  transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
  -webkit-transition: 0.4s ease-in-out;
  display: flex;
}

.page-footer ul li a:hover {
  color: #ef5051 !important;
}

.page-footer .contact-us ul li span {
  width: 23px;
  height: 21px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  flex: 0 0 auto;
}

.page-footer .contact-us ul li span.phone {
  background-position: 0 0;
}

.page-footer .contact-us ul li span.mail {
  background-position: 23px 0;
}

.page-footer .social-icons li {
  display: inline-block;
}

.page-footer .social-icons li a {
  width: 39px;
  height: 39px;
  display: inline-block;
  color: #2a2525;
  font-size: 17px;
  text-align: center;
  line-height: 39px;
  margin-right: 10px;
  margin-bottom: 8px;
  transition: 0.4s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.page-footer .social-icons li:nth-child(5n + 5) a {
  margin-right: 0;
}

.page-footer .social-icons li a:hover {
  background-color: #fff;
}

.page-footer .footer-links ul li:last-child,
.page-footer .contact-us ul li:last-child {
  margin-bottom: 0;
}

.page-footer .social-media ul {
  width: 100%;
}

.download-icon a{
  align-items: center;
}

@media (max-width: 1199px) {
  .page-footer ul li a,
  .page-footer ul li {
    font-size: 14px;
  }

  .page-footer h6 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .footer-logo {
    max-width: 200px;
  }
}

@media (max-width: 991px) {
  footer {
    background-size: 50%;
  }

  .page-footer [class*="col-"]:first-child {
    text-align: center;
  }

  .page-footer p {
    margin: 0 auto 20px;
  }

  footer p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  footer {
    background-size: 100%;
  }

  .page-footer {
    padding: 40px 20px;
  }

  .page-footer p {
    text-align: center;
  }
}
