.key-features-section .key-features-body .key-features-body-item span {
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(160, 194, 255, 0.2);
  font-size: 20px;
  color: #ef5051;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: absolute;
  top: 0px;
  left: 0px;
}

.key-features-section .key-features-body .key-features-body-item {
  position: relative;
  padding-left: 55px;
  margin-bottom: 30px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.key-features-section .key-features-body .key-features-body-item .item-animation {
  visibility: visible;
  animation-duration: 0.5s;
  animation-delay: 0.4s;
  animation-name: fadeInLeft;
}

.key-features-section .key-features-body .key-features-body-item .title {
  font-size: 18px;
  font-weight: 400;
  line-height: inherit;
}

.key-features-section .key-features-body .key-features-body-item p {
  color: #81a3bb;
  padding-top: 0;
  padding-right: 80px;
}

.key-features-section .key-features-body .key-features-body-item:hover span {
  background: #ef5051;
  color: #fff;
}

.key-features-body p {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .key-features-section .key-features-body .key-features-body-item .title {
    font-size: 15px;
  }

  .key-features-section .key-features-body .key-features-body-item {
    margin-bottom: 10px;
    padding-left: 40px;
  }

  .key-features-section .key-features-body .key-features-body-item span {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-top: 4px;
  }

  .key-features-section .choose-thumb-mobile img {
    width: 100%;
    max-height: 300px;
  }
}
