.fact-section {
  position: relative;
  z-index: 1;
}

.fact-boxes .fact-box .icon {
  color: #ef5051;
  line-height: 1;
  margin-bottom: 20px;
  font-size: 70px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.fact-boxes .fact-box .counter {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -1px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.fact-boxes .fact-box p.title {
  line-height: 1.2;
  margin-top: 5px;
  text-transform: uppercase;
}

.fact-boxes .fact-box.fact-box-two .counter {
  color: #fff;
}

.fact-boxes .fact-box.fact-box-two .title {
  color: #6f8697;
}

.fact-boxes .fact-box.fact-box-three {
  background-color: #222f3d;
  color: #fff;
  border-radius: 15px;
  padding: 40px 20px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
  height: 100%;
}

.fact-boxes .fact-box.fact-box-three::before {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../../../public/assets/images/section-images/fact-box-before.png);
  background-size: cover;
  content: "";
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.fact-boxes .fact-box.fact-box-three:hover {
  background-color: #ef5051;
}

.fact-boxes .fact-box.fact-box-three:hover,
.fact-boxes .fact-box.fact-box-three:hover .icon,
.fact-boxes .fact-box.fact-box-three:hover .counter {
  color: #fff;
}

.fact-boxes .fact-box.fact-box-three:hover::before {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1199px) {
  .fact-boxes .fact-box.fact-box-three .counter {
    font-size: 46px;
  }
}
