.copyright-section {
  position: relative;
  padding: 5px 0px;
  border-top: 1px solid grey;
  display: inline-block;
  width: 100%;
  color: #fff;
  line-height: 39px;
}

.copyright-section span a {
  color: #fff;
}

.copyright-section span {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}

.copyright .social-icons li {
  display: inline-flex;
  justify-content: flex-end;
}

.copyright .social-icons .phone-number {
 cursor: pointer;
}

.social-icons {
  text-align: end;
}
.copyright .social-icons li a {
  width: 39px;
  height: 39px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 39px;
  margin-right: 10px;
  margin-bottom: 8px;
  transition: 0.4s ease-in-out;
}

.copyright .social-icons li:nth-child(5n + 5) a {
  margin-right: 0;
}

.copyright .social-icons li a:hover,
.copyright .social-icons .phone-number:hover {
  color: #f8646b;
  transition: 0.4s ease-in-out;
}

.copyright .footer-links ul li:last-child,
.copyright .contact-us ul li:last-child {
  margin-bottom: 0;
}

.copyright .social-media ul {
  width: 100%;
}

@media (max-width: 767px) {
  .copyright-section {
    padding: 15px 0px;
  }

  .copyright .social-icons li a{
    width: 35px;
    height: 35px;
    margin: 0;
    margin-right: 0.5rem;
  }
  .social-icons{
    text-align: center;
  }

  .copyright-section .back-to-top {
    height: 55px;
    width: 55px;
    line-height: 55px;
  }

  .copyright-section,
  .copyright-section .reserve-text {
    text-align: center;
  }
}
